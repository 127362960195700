import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setWarning } from "../../../state/uiSlice";
import useSynthetixHandlerStorage from "../contracts/use-synthetix-handler-storage";
import { useEthers } from "@usedapp/core";
import { ScaledNumber } from "scaled-number";
import useLeveragedToken from "../contracts/use-leveraged-token";
import useChainData from "../utils/use-chain-data";

const useExitLoad = (
  leveragedTokenAddress: string,
  amount: ScaledNumber
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const synthetixHandlerStorage = useSynthetixHandlerStorage();
  const leveragedToken = useLeveragedToken(leveragedTokenAddress);
  const chainData = useChainData();
  const { account } = useEthers();
  const [value, setValue] = useState<ScaledNumber | null>(null);

  const isV2 = chainData?.v2;

  useEffect(() => {
    const getValue = async () => {
      if (!account) return;

      try {
        if (isV2) {
          if (!leveragedToken) return;
          if (!leveragedToken.decayingRedemptionFee) return;

          const value_ = await leveragedToken.decayingRedemptionFee(
            account,
            amount.value
          );
          setValue(new ScaledNumber(value_));
        } else {
          if (!leveragedTokenAddress) return;
          if (!synthetixHandlerStorage) return;

          const value_ =
            await synthetixHandlerStorage.decayingRedemptionFeeFromLt(
              leveragedTokenAddress,
              account,
              amount.value
            );
          setValue(new ScaledNumber(value_));
        }
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching exit load"));
      }
    };

    getValue();
  }, [
    dispatch,
    synthetixHandlerStorage,
    leveragedTokenAddress,
    account,
    amount.value,
    isV2,
    leveragedToken,
  ]);

  return value;
};

export default useExitLoad;
