import useContract from "../utils/use-contract";
import abi from "../abis/wrapper-abi.json";
import useAddresses from "../utils/use-addresses";

const useWrapper = () => {
  const addresses = useAddresses();
  return useContract(addresses?.WRAPPER_HELPER, abi);
};

export default useWrapper;
