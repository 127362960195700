import { LeveragedTokenDataType } from "../../app/web3/views/use-leveraged-token-data";
import getLeverageRange from "../../app/helpers/get-leverage-range";
import InfoSection from "../../components/InfoSection";
import { shortenAddress } from "@usedapp/core";
import useBlockExplorerLink from "../../app/web3/utils/use-block-explorer-link";
import useChainData from "../../app/web3/utils/use-chain-data";

interface Props {
  data: LeveragedTokenDataType | undefined;
}

const MintInfoSection = ({ data }: Props) => {
  const blockExplorerLink = useBlockExplorerLink();
  const chainData = useChainData();
  const isV2 = !!chainData && chainData.v2;
  const { lowerLeverage, upperLeverage } = getLeverageRange(data, isV2);

  const symbol = data?.symbol || "---";

  return (
    <InfoSection
      lines={[
        {
          label: "Current Leverage",
          value: data ? data.leverage.toNumber().toFixed(1) : "---",
          unit: "x",
          tooltip: `The current leverage of the leveraged token position. Will vary between ${
            lowerLeverage ? lowerLeverage.toNumber().toFixed(2) : "---"
          } and ${upperLeverage ? upperLeverage.toNumber().toFixed(2) : "---"}`,
        },
        {
          label: "Leverage Range",
          value:
            lowerLeverage && upperLeverage
              ? `${lowerLeverage.toNumber().toFixed(2)} - ${upperLeverage
                  .toNumber()
                  .toFixed(2)}`
              : "-- - --",
          unit: "x",
          tooltip:
            "The minimum and maximum leveraged thresholds for triggering a rebalance",
        },
        {
          label: `${symbol} Address`,
          value: data ? shortenAddress(data.addr) : "---",
          unit: "",
          tooltip: `The contract address of the ${symbol} token.`,
          link: data ? blockExplorerLink(data.addr) : "",
        },
        {
          label: "Notional Value",
          value: data
            ? Math.round(
                data.totalSupply
                  .mul(data.exchangeRate)
                  .mul(data.targetLeverage)
                  .toNumber()
              ).toLocaleString()
            : "---",
          unit: "sUSD",
          tooltip:
            "The notional value of the total leveraged token position in sUSD.",
        },
      ]}
    />
  );
};

export default MintInfoSection;
