import { EvmPriceServiceConnection } from "@pythnetwork/pyth-evm-js";

const KEY = "HERMESTOKV1MPLSSG922CZ";
const ENDPOINT = `https://gateway-usa.liquify.com/api=${KEY}`;

const getPriceUpdateData = async (feedId: string) => {
  const connection = new EvmPriceServiceConnection(ENDPOINT);
  const priceIds = [feedId];
  const priceUpdateData = await connection.getPriceFeedsUpdateData(priceIds);
  return priceUpdateData[0];
};

export default getPriceUpdateData;
