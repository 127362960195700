import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useZap from "../contracts/use-zap";
import useChainData from "../utils/use-chain-data";
import useAddresses from "../utils/use-addresses";

const useSupportedZapTokens = (): string[] | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const [tokens, setTokens] = useState<string[] | null>(null);
  const zap = useZap();
  const chainData = useChainData();
  const addresses = useAddresses();

  useEffect(() => {
    if (!zap) return;
    if (!supportedChain) return;

    const getAmount = async () => {
      try {
        const tokens_ = await zap.supportedZapAssets();

        setTokens(tokens_);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching supported zap tokens"));
      }
    };

    getAmount();
  }, [zap, supportedChain, dispatch]);

  if (chainData && chainData.v2 && addresses) return [addresses.USDC];

  return tokens;
};

export default useSupportedZapTokens;
