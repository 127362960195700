import useContract from "../utils/use-contract";
import abi from "../abis/perps-market-abi.json";
import useAddresses from "../utils/use-addresses";

const usePerpsMarket = () => {
  const addresses = useAddresses();
  return useContract(addresses?.PERPS_MARKET, abi);
};

export default usePerpsMarket;
