import styled from "styled-components";

import { useEffect } from "react";
import { CardFrame } from "../styles/Frames";

const StyledPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transform: translateZ(0);

  @media (max-width: 900px) {
    padding: 0 1.6rem;
  }
`;

const Background = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.64);
`;

interface ContentProps {
  $smallPadding?: boolean;
}

const Content = styled(CardFrame)<ContentProps>`
  position: relative;
  padding: ${(props) => (props.$smallPadding ? "1.6rem" : "2.4rem")};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    padding: 1.6rem;
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2.4rem;

  @media (max-width: 900px) {
    margin-bottom: 1.6rem;
  }
`;

const Header = styled.h2`
  font-size: 3.2rem;
  font-weight: 400;
  flex: 1;

  @media (max-width: 900px) {
    font-size: 2.4rem;
  }
`;

interface Props {
  children: React.ReactNode;
  open: boolean;
  close: () => void;
  header?: string;
}

const Popup = ({ children, open, close, header }: Props) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  if (!open) return null;

  return (
    <StyledPopup>
      <Background onClick={close} />
      <Content $smallPadding={!header}>
        {header && (
          <HeaderContainer>
            <Header>{header}</Header>
          </HeaderContainer>
        )}
        {children}
      </Content>
    </StyledPopup>
  );
};

export default Popup;
