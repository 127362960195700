import useTokenMetadata from "./use-token-metadata";

const useTokenId = (tokenAddress: string | undefined): string | null => {
  const tokenMetadata = useTokenMetadata();
  if (!tokenAddress) return null;
  if (!tokenMetadata) return null;
  const ids = Object.keys(tokenMetadata);
  const values = Object.values(tokenMetadata);
  const index = values.findIndex(
    (metadata) => metadata.address === tokenAddress
  );
  if (index === -1) return null;
  return ids[index];
};

export default useTokenId;
