import styled from "styled-components";

import tooltip from "../assets/ui/tooltip.svg";
import successTooltip from "../assets/ui/success-tooltip.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  margin-left: 0.6rem;

  &:hover {
    & > div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Icon = styled.img`
  height: 1.5rem;
`;

const StyledTooltip = styled.div`
  position: absolute;
  padding: 1.4rem 1.6rem;
  border: var(--border);
  border-radius: var(--border-radius);
  background: var(--bg-1);
  width: 36rem;
  top: 1.6rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  color: var(--main);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
`;

interface Props {
  success?: boolean;
  children: React.ReactNode;
}

const Tooltip = ({ success, children }: Props) => {
  return (
    <Container>
      <Icon src={success ? successTooltip : tooltip} />
      <StyledTooltip>{children}</StyledTooltip>
    </Container>
  );
};

export default Tooltip;
