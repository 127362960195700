export const DISCORD = "https://discord.com/invite/synthetix";
export const TWITTER = "https://x.com/synthetix_io";
export const GITHUB = "https://github.com/synthetixio";
export const BLOG = "https://blog.synthetix.io/";
export const SNAPSHOT = null;
export const DOCS = "https://docs.synthetix.io/leveraged-tokens";
export const GENESIS_LOCKER_DOCS =
  "https://docs.tlx.fi/basics/tokenomics/understanding-usdtlx#genesis-locking";
export const STAKE_DOCS =
  "https://docs.tlx.fi/basics/tokenomics/understanding-usdtlx#staking";
export const REFERRALS_DOCS =
  "https://docs.synthetix.io/leveraged-tokens/basics/fees#rebates";
export const TLX_VELODROME_LP =
  "https://velodrome.finance/deposit?token0=0x4200000000000000000000000000000000000006&token1=0xD9cC3D70E730503E7f28c1B407389198c4B75FA2&type=-1";
export const DEFILLAMA_API_DOCS = "https://defillama.com/docs/api";
export const TLX_YIELD_SERVER =
  "https://github.com/DefiLlama/yield-server/blob/master/src/adaptors/tlx-finance/index.ts";
export const VELODROME_YIELD_SERVER =
  "https://github.com/DefiLlama/yield-server/blob/master/src/adaptors/velodrome-v2/index.js";
export const OP_INCENTIVES_BLOG =
  "https://mirror.xyz/tlx.eth/2TcZsmWIiB3tEE7u0HK9gB5I98HGdIK3uWA2nGZ2vcg";
export const TLX_TO_SNX_MIGRATION = "https://tlx-migration.synthetix.io/";
