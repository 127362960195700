import styled from "styled-components";
import Popup from "./Popup";
import { useLocation, useNavigate } from "react-router-dom";
import useChainId from "../app/web3/utils/use-chain-id";
import Button from "./Button";
import { useEthers } from "@usedapp/core";
import SUPPORTED_CHAINS from "../app/constants/supported-chains";

const StyledWrongNetwork = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  width: 33rem;
`;

const Paragraph = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.4rem;
`;

const WrongNetwork = () => {
  const chainId = useChainId();
  const navigate = useNavigate();
  const location = useLocation();
  const { switchNetwork } = useEthers();

  const sortedChains = SUPPORTED_CHAINS.sort(
    (a, b) => b.assetData.volume - a.assetData.volume
  );

  // Getting the supported chains as a string
  let supports = sortedChains.reduce((acc, chain) => {
    return `${acc}${chain.assetData.name}, `;
  }, "");
  supports = supports.slice(0, -2);
  const lastComma = supports.lastIndexOf(",");
  supports = `${supports.slice(0, lastComma)} and${supports.slice(
    lastComma + 1
  )}`;

  const mainChain = sortedChains[0];

  return (
    <Popup
      open={
        chainId !== null &&
        !SUPPORTED_CHAINS.some((chain) => chain.chainId === chainId) &&
        location.pathname !== "/"
      }
      close={() => navigate("/")}
      header="Unsupported Network"
    >
      <StyledWrongNetwork>
        <Paragraph>
          {`X Leverage currently only supports ${supports}. Switch to ${mainChain?.assetData.name} to
          continue using X Leverage.`}
        </Paragraph>
        <Button wide action={() => switchNetwork(mainChain?.chainId)} primary>
          {`Switch to ${mainChain?.assetData.name}`}
        </Button>
      </StyledWrongNetwork>
    </Popup>
  );
};

export default WrongNetwork;
