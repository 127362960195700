import styled from "styled-components";
import susdIcon from "../../assets/ui/susd.svg";
import { ScaledNumber } from "scaled-number";
import getLeveragedTokenIcon from "../../app/helpers/get-leveraged-token-icon";
import getLeveragedTokenSymbol from "../../app/helpers/get-leveraged-token-symbol";
import round from "../../app/helpers/round";
import useChainId from "../../app/web3/utils/use-chain-id";

const StyledLeveragedTokenAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.3rem;
`;

const LargeValue = styled.div`
  font-size: 2.4rem;
  font-weight: 500;
  margin-left: 0.8rem;
`;

const SusdIcon = styled.img`
  height: 2.3rem;
  margin-left: 0.7rem;
  transform: translateY(0.1rem);
`;

const LeveragedTokenIcon = styled.img`
  height: 5rem;
`;

interface Props {
  assetId: string;
  leverage: number;
  isLong: boolean;
  expectedOut: ScaledNumber | null;
  exchangeRate: ScaledNumber | null;
}

const LeveragedTokenAmount = ({
  assetId,
  leverage,
  isLong,
  expectedOut,
  exchangeRate,
}: Props) => {
  const chainId = useChainId();

  return (
    <StyledLeveragedTokenAmount>
      <Section>
        <LeveragedTokenIcon
          src={getLeveragedTokenIcon(
            getLeveragedTokenSymbol(assetId, leverage, isLong, chainId)
          )}
        />
        <LargeValue>
          {getLeveragedTokenSymbol(assetId, leverage, isLong, chainId)}
        </LargeValue>
      </Section>
      <Column>
        <Section>
          <LargeValue>
            {expectedOut && exchangeRate
              ? round(
                  expectedOut.mul(exchangeRate).toNumber(),
                  2
                ).toLocaleString()
              : "---"}
          </LargeValue>
          <SusdIcon src={susdIcon} alt="sUSD Icon" />
        </Section>
      </Column>
    </StyledLeveragedTokenAmount>
  );
};

export default LeveragedTokenAmount;
