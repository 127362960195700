import styled from "styled-components";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Mainnet, DAppProvider, Config } from "@usedapp/core";

import LandingPage from "./pages/landing/LandingPage";
import Header from "./components/Header";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import StakePage from "./pages/stake/StakePage";
import MintPage from "./pages/mint/MintPage";
import PortfolioPage from "./pages/portfolio/PortfolioPage";
import LeaderBoardPage from "./pages/leaderboard/LeaderBoardPage";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import ErrorHandler from "./components/ErrorHandler";
import WrongNetwork from "./components/WrongNetwork";
import LockPage from "./pages/locker/LockerPage";
import {
  LEADERBOARD_PATH,
  LOCK_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  REWARDS_PATH,
  STAKE_PATH,
  VESTING_PATH,
} from "./app/constants/paths";
import VestingPage from "./pages/vesting/VestingPage";
import RewardsPage from "./pages/rewards/RewardsPage";
import MobileNav from "./components/MobileNav";
import Login from "./components/Login";
import ReferralCodeHandler from "./components/ReferralCodeHandler";
import Toast from "./components/Toast";
import BlockUpdateHandler from "./components/BlockUpdateHandler";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
`;

const queryClient = new QueryClient();

const Layout = () => {
  return (
    <StyledApp>
      <ScrollToTop />
      <Header />
      <Outlet />
      <Footer />
      <MobileNav />
      <Toast />

      <ReferralCodeHandler />
      <ErrorHandler />
      <BlockUpdateHandler />

      <WrongNetwork />
      <Login />
    </StyledApp>
  );
};

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {},
};

const App = () => {
  const location = useLocation();

  return (
    <StyledApp
      style={{
        background: ["/"].includes(location.pathname) ? "none" : "var(--bg)",
      }}
    >
      <QueryClientProvider client={queryClient}>
        <DAppProvider config={config}>
          <HelmetProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<LandingPage />} />

                <Route path={LOCK_PATH} element={<LockPage />} />

                <Route path={VESTING_PATH}>
                  <Route index element={<VestingPage />} />
                  <Route path=":addressOverride" element={<VestingPage />} />
                </Route>

                <Route path={REWARDS_PATH} element={<RewardsPage />} />
                <Route path={STAKE_PATH} element={<StakePage />} />
                <Route path={MINT_PATH} element={<MintPage />} />
                <Route path={PORTFOLIO_PATH} element={<PortfolioPage />} />
                <Route path={LEADERBOARD_PATH} element={<LeaderBoardPage />} />

                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </HelmetProvider>
        </DAppProvider>
      </QueryClientProvider>
    </StyledApp>
  );
};

export default App;
