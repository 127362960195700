import { TokenMetadataType } from "../web3/views/use-token-metadata";

const _getTokenId = (
  tokenAddress: string,
  tokenMetadata: Record<string, TokenMetadataType>
): string | null => {
  const ids = Object.keys(tokenMetadata);
  const values = Object.values(tokenMetadata);
  const index = values.findIndex(
    (metadata) => metadata.address === tokenAddress
  );
  if (index === -1) return null;
  return ids[index];
};

const getTokenId = (
  tokenAddress: string,
  tokenMetadata: Record<string, TokenMetadataType>
): string => {
  const id = _getTokenId(tokenAddress, tokenMetadata);
  if (!id) throw new Error(`Token ID not found for address: ${tokenAddress}`);
  return id;
};

export default getTokenId;
