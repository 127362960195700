import useContract from "../utils/use-contract";
import abi from "../abis/referrals-abi.json";
import useAddresses from "../utils/use-addresses";

const useReferrals = () => {
  const addresses = useAddresses();
  return useContract(addresses?.REFERRALS, abi);
};

export default useReferrals;
