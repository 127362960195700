import styled from "styled-components";

import { useEffect, useState } from "react";
import TextSection from "../../components/TextSection";
import ContentSection from "../../components/ContentSection";
import HowItWorks from "../../components/HowItWorks";
import Hero from "./Hero";
import PoweredBy from "../../components/PoweredBy";

import mobile01 from "../../assets/landing-page/mobile-01.jpg";
import mobile02 from "../../assets/landing-page/mobile-02.jpg";
import mobile03 from "../../assets/landing-page/mobile-03.jpg";
import { LANDING_PAGE_SECTIONS } from "../../app/constants/config";
import Header from "../../components/Header";

const StyledLandingPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    height: auto !important;
  }
`;

const FakeScrollContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${LANDING_PAGE_SECTIONS}dvh;

  @media (max-width: 900px) {
    transform: none !important;
    position: relative;
    opacity: 1 !important;
    height: auto;
  }
`;

const LandingPage = () => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const percentage_ = window.scrollY / ((LANDING_PAGE_SECTIONS - 1) * 1000);
      setPercentage(percentage_);
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <StyledLandingPage
      style={{
        height: `calc(${(LANDING_PAGE_SECTIONS - 1) * 1000}px + 100dvh`,
      }}
    >
      <FakeScrollContainer
        style={{
          transform: `translateY(-${
            (LANDING_PAGE_SECTIONS - 1) * 100 * percentage
          }dvh)`,
        }}
      >
        <ContentSection percentage={percentage} section={0}>
          <Hero />
        </ContentSection>
        <TextSection
          percentage={percentage}
          section={1}
          header="Non-liquidating"
          subHeader="Leveraged tokens maintain a constant leverage via reactive rebalancing, eliminating the need for margin management"
          image={mobile01}
          background
        />
        <TextSection
          percentage={percentage}
          section={2}
          header="Streamlined"
          subHeader="Minting and redeeming requires just a few clicks - no active maintenance, no complicated UI, no stress"
          image={mobile02}
        />
        <TextSection
          percentage={percentage}
          section={3}
          header="Composable"
          subHeader="ERC20 compliance facilitates the seamless integration of leveraged tokens into the wider DeFi ecosystem"
          image={mobile03}
        />
        <ContentSection percentage={percentage} section={4}>
          <HowItWorks />
        </ContentSection>
        <ContentSection percentage={percentage} section={5}>
          <PoweredBy />
        </ContentSection>
      </FakeScrollContainer>
      <Header />
    </StyledLandingPage>
  );
};

export default LandingPage;
