import useContract from "../utils/use-contract";
import abi from "../abis/staker-abi.json";
import useAddresses from "../utils/use-addresses";

const useStaker = () => {
  const addresses = useAddresses();
  return useContract(addresses?.STAKER, abi);
};

export default useStaker;
