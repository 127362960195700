import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import useToken from "../contracts/use-token";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";
import useTokenDecimals from "../contracts/use-token-decimals";

const useApprovedAmount = (
  tokenAddress: string | undefined,
  spenderAddress: string | undefined
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const { account } = useEthers();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);
  const block = useBlock();
  const token = useToken(tokenAddress);
  const tokenDecimals = useTokenDecimals(tokenAddress);
  const multicall = useMulticall();

  useEffect(() => {
    if (!token) return;
    if (!account) return;
    if (!supportedChain) return;
    if (!tokenAddress) return;
    if (token.address !== tokenAddress) return;
    if (!spenderAddress) return;

    const getAmount = async () => {
      try {
        if (tokenDecimals !== null) {
          const amount_ = await token.allowance(account, spenderAddress);
          setAmount(new ScaledNumber(amount_, tokenDecimals));
        } else {
          const [amount_, decimals_] = await multicall([
            token.multi.allowance(account, spenderAddress),
            token.multi.decimals(),
          ]);
          setAmount(new ScaledNumber(amount_, decimals_));
        }
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching approved amount"));
      }
    };

    getAmount();
  }, [
    token,
    tokenAddress,
    tokenDecimals,
    spenderAddress,
    account,
    block,
    supportedChain,
    dispatch,
    multicall,
  ]);

  return amount;
};

export default useApprovedAmount;
