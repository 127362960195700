import styled from "styled-components";
import InfoBox from "../../components/InfoBox";

import timer from "../../assets/greeble/timer.svg";
import checkSquare from "../../assets/greeble/check-square.svg";
import useLockerData from "../../app/web3/views/use-locker-data";
import { ScaledNumber } from "scaled-number";
import getCountdownText from "../../app/helpers/get-countdown-text";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useDispatch } from "react-redux";
import useLocker from "../../app/web3/contracts/use-locker";
import unlocked from "../../assets/greeble/unlocked.svg";
import Button from "../../components/Button";
import { setError } from "../../state/uiSlice";
import { useNavigate } from "react-router-dom";
import { STAKE_PATH } from "../../app/constants/paths";

const StyledUnlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.4rem;
  gap: 2.4rem;
`;

const Unlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lockerData = useLockerData();
  const lockerContract = useLocker();

  const now = new Date();

  const hasLock =
    lockerData && lockerData.userLocked.gt(ScaledNumber.fromUnscaled(0));

  const readyToUnlock =
    hasLock &&
    (lockerData.unlockTime.getTime() < now.getTime() || lockerData.isShutdown);

  return (
    <StyledUnlock>
      <LoadingSpinner show={!lockerData} />
      {readyToUnlock && (
        <InfoBox
          success
          icon={unlocked}
          text="Your TLX tokens are unlocked. You can now withdraw them to your wallet."
        />
      )}
      {hasLock && !readyToUnlock && (
        <InfoBox
          primary
          icon={timer}
          text={`Your TLX tokens will unlock in ${getCountdownText(
            lockerData.unlockTime
          )}`}
        />
      )}
      {!hasLock && (
        <InfoBox
          icon={checkSquare}
          text="No TLX locked. Lock TLX to earn TLX rewards."
        />
      )}
      {readyToUnlock && (
        <Button
          primary
          wide
          web3
          action={async () => {
            if (!lockerContract) return;

            try {
              const tx = await lockerContract.migrate();
              await tx.wait();
              navigate(`/${STAKE_PATH}`);
            } catch (e: any) {
              dispatch(
                setError({
                  message: e.message,
                  source: "Unlock/Migrate",
                })
              );
            }
          }}
        >
          Withdraw TLX
        </Button>
      )}
    </StyledUnlock>
  );
};

export default Unlock;
