import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import useReferrals from "../contracts/use-referrals";
import { useEthers } from "@usedapp/core";
import { setWarning } from "../../../state/uiSlice";
import useChainData from "../utils/use-chain-data";

const useRebatePercent = (): ScaledNumber | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const chainData = useChainData();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);
  const contract = useReferrals();
  const { account } = useEthers();

  const isV2 = chainData?.v2;

  useEffect(() => {
    if (isV2) {
      setAmount(new ScaledNumber(0));
      return;
    }
    if (!contract) return;
    if (!supportedChain) return;
    if (!account) return;

    const getAmount = async () => {
      try {
        const amount_ = await contract.rebatePercent();
        setAmount(new ScaledNumber(amount_));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching rebate percent"));
      }
    };

    getAmount();
  }, [supportedChain, contract, dispatch, account, isV2]);

  return amount;
};

export default useRebatePercent;
