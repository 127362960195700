import styled from "styled-components";
import Checkbox from "./Checkbox";

interface StepProps {
  disabled?: boolean;
}

const StyledStep = styled.div<StepProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  border: var(--border);
  box-shadow: var(--box-shadow);
  background: var(--bg-2);
  height: 10rem;
  border-radius: var(--border-radius);
  padding: 1.6rem 2.4rem;
  display: flex;
  width: 100%;

  opacity: ${(props: StepProps) => (props.disabled ? "0.5" : "1")};

  @media (max-width: 900px) {
    height: 6rem;
    margin-bottom: 1.6rem;
    border-radius: 1.6rem;
    padding: 0.8rem 1.2rem;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 2.2rem;
  font-weight: 500;
  margin-left: 1.6rem;

  @media (max-width: 900px) {
    font-size: 1.6rem;
    margin-left: 0.8rem;
  }
`;

interface Props {
  children: React.ReactNode;
  label: string;
  completed: boolean;
  disabled?: boolean;
}

const Step = ({ completed, label, children, disabled }: Props) => {
  return (
    <StyledStep disabled={disabled}>
      <Section>
        <Checkbox checked={completed} />
        <Label>{label}</Label>
      </Section>
      {children}
    </StyledStep>
  );
};

export default Step;
