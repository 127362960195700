import styled from "styled-components";
import { useEffect, useState } from "react";
import Popup from "./Popup";
import Input from "./Input";

import searchIcon from "../assets/ui/search.svg";
import { AssetData } from "../app/helpers/get-asset-data";
import AssetOption from "./AssetOption";

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 28rem;
`;

const OptionHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
`;

const OptionContainer = styled.div`
  width: 100%;
  height: calc(4rem * 6.6);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  options: AssetData[];
  setActive: (assetId: string) => void;
}

const AssetSelectorPopup = ({
  isOpen,
  setIsOpen,
  options,
  setActive,
}: Props) => {
  const [input, setInput] = useState<string>("");

  const filtered = options
    .filter((option) => {
      if (!input) return true;
      return (
        option.name.toLowerCase().includes(input.toLowerCase()) ||
        option.symbol?.toLowerCase().includes(input.toLowerCase())
      );
    })
    .sort((a, b) => {
      if (a.comingSoon && !b.comingSoon) return 1;
      if (!a.comingSoon && b.comingSoon) return -1;
      return b.volume - a.volume;
    });

  const onlyOneOption = filtered.length === 1;

  const close = () => {
    setIsOpen(false);
    setInput("");
  };

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        if (filtered.length > 0) {
          setActive(filtered[0].id);
          setIsOpen(false);
          setInput("");
        }
      }
    };

    window.addEventListener("keydown", handleEnter);

    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [filtered, setActive, setIsOpen]);

  const hasSomeBalance = filtered.some(
    (option) => option.balance && !option.balance.isZero()
  );

  return (
    <Popup open={isOpen} close={close}>
      <SelectorContainer>
        <Input
          value={input}
          setValue={setInput}
          placeholder={`Search by name${
            !!options[0]?.symbol ? " or symbol" : ""
          }`}
          icon={searchIcon}
        />
        <OptionContainer>
          {hasSomeBalance && <OptionHeader>Your tokens</OptionHeader>}
          {filtered
            .filter((option) => option.balance && !option.balance.isZero())
            .sort((a, b) => b.balance!.toNumber() - a.balance!.toNumber())
            .map((option) => (
              <AssetOption
                key={option.id}
                onlyOneOption={onlyOneOption}
                option={option}
                setActive={setActive}
                close={close}
              />
            ))}
          {hasSomeBalance && <OptionHeader>Other tokens</OptionHeader>}
          {filtered
            .filter((option) => !option.balance || option.balance.isZero())
            .map((option) => (
              <AssetOption
                key={option.id}
                onlyOneOption={onlyOneOption}
                option={option}
                setActive={setActive}
                close={close}
              />
            ))}
        </OptionContainer>
      </SelectorContainer>
    </Popup>
  );
};

export default AssetSelectorPopup;
