import useContract from "../utils/use-contract";
import abi from "../abis/parameter-provider-abi.json";
import useAddresses from "../utils/use-addresses";

const useParameterProvider = () => {
  const addresses = useAddresses();
  return useContract(addresses?.PARAMETER_PROVIDER, abi);
};

export default useParameterProvider;
