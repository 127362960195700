import { AssetData } from "../helpers/get-asset-data";

import baseLogo from "../../assets/chains/base.svg";
import optimismLogo from "../../assets/chains/optimism.svg";
import { BASE_RPC, OPTIMISM_RPC } from "./endpoints";

interface SupportedChainType {
  chainId: number;
  blockExplorer: string;
  assetData: AssetData;
  leveragedTokens: Record<string, number[]>;
  v2: boolean;
  rpc: string;
  shortingEnabled: boolean;
}

const SUPPORTED_CHAINS: SupportedChainType[] = [
  {
    chainId: 10,
    v2: false,
    blockExplorer: "https://optimistic.etherscan.io",
    assetData: {
      id: "optimism",
      icon: optimismLogo,
      name: "Optimism",
      volume: 1,
      color: "pink",
    },
    leveragedTokens: {
      // Blue Chip
      ETH: [1, 2, 3, 4, 5, 7],
      SOL: [1, 2, 3, 4, 5],
      BTC: [1, 2, 3, 4, 5, 7],

      // Long Tail
      LINK: [2, 5],
      OP: [2, 5],
      PEPE: [2, 5],
      DOGE: [2, 5],
      SUI: [2, 5],
      SEI: [2, 5],
      RUNE: [2, 5],

      // Low Volatility
      ETHBTC: [2, 5, 10],
    },
    rpc: OPTIMISM_RPC,
    shortingEnabled: true,
  },
  {
    chainId: 8453,
    v2: true,
    blockExplorer: "https://basescan.org/",
    assetData: {
      id: "base",
      icon: baseLogo,
      name: "Base",
      volume: 2,
      color: "pink",
    },
    leveragedTokens: {
      ETH: [3],
      BTC: [3],
      SOL: [3],
      XRP: [3],
      PEPE: [3],
      DOGE: [3],
      WIF: [3],
      // PNUT: [3],
      BONK: [3],
      FARTCOIN: [3],
      TRUMP: [3],
    },
    rpc: BASE_RPC,
    shortingEnabled: false,
  },
];

export default SUPPORTED_CHAINS;
