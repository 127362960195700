import SUPPORTED_CHAINS from "../../constants/supported-chains";
import useChainId from "./use-chain-id";

const useChainData = () => {
  const chainId = useChainId();
  if (!chainId) return null;
  const chain = SUPPORTED_CHAINS.find((chain) => chain.chainId === chainId);
  if (!chain) return null;
  return chain;
};

export default useChainData;
