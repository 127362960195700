import { useEffect, useState } from "react";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import usePerpsMarket from "../contracts/use-perps-market";

const useFeedId = (marketId: bigint | undefined): string | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const perpsMarket = usePerpsMarket();
  const [feedId, setFeedId] = useState<string | null>(null);
  const block = useBlock();

  useEffect(() => {
    if (!supportedChain) return;
    if (!marketId) return;
    if (!perpsMarket) return;

    const getAmount = async () => {
      try {
        const settlementStrategy = await perpsMarket.getSettlementStrategy(
          marketId,
          0
        );

        setFeedId(settlementStrategy.feedId);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching feed id"));
      }
    };

    getAmount();
  }, [block, supportedChain, dispatch, marketId, perpsMarket]);

  return feedId;
};

export default useFeedId;
