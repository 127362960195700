import ADDRESSES_PER_CHAIN, { AddressesType } from "../../constants/addresses";
import useChainId from "./use-chain-id";

const useAddresses = (): AddressesType | null => {
  const chainId = useChainId();
  if (!chainId) return null;
  const addresses = ADDRESSES_PER_CHAIN[chainId];
  if (!addresses) return null;
  return addresses;
};

export default useAddresses;
