import tlx from "../../../assets/tokens/tlx.svg";
import susd from "../../../assets/tokens/susd.svg";
import velo from "../../../assets/tokens/velo.svg";
import useAddresses from "../utils/use-addresses";
import useChainId from "../utils/use-chain-id";

export interface TokenMetadataType {
  name: string;
  symbol: string;
  icon: string;
  address: string;
  decimals: number;
  stable: boolean;
  marketCap?: number; // Used for choosing display order
}

const URL =
  "https://raw.githubusercontent.com/SmolDapp/tokenAssets/main/tokens/";

const getLogo = (address: string, chainId: number) => {
  if (!address) return "";
  return `${URL}${chainId}/${address.toLowerCase()}/logo.svg`;
};

const useTokenMetadata = () => {
  const addresses = useAddresses();
  const chainId = useChainId();

  if (!addresses) return null;
  if (!chainId) return null;

  const TOKEN_METADATA: { [key: string]: TokenMetadataType } = {
    tlx: {
      name: "TLX Token",
      symbol: "TLX",
      icon: tlx,
      address: addresses.TLX,
      decimals: 18,
      stable: false,
    },
    susd: {
      name: "Synthetix USD",
      symbol: "sUSD",
      icon: susd,
      address: addresses.SUSD,
      decimals: 18,
      stable: true,
      marketCap: 23_848_322,
    },
    velo: {
      name: "Velodrome",
      symbol: "VELO",
      icon: velo,
      address: addresses.VELO,
      decimals: 18,
      stable: false,
    },
    dai: {
      name: "DAI",
      symbol: "DAI",
      address: addresses.DAI,
      icon: getLogo(addresses.DAI, chainId),
      decimals: 18,
      stable: true,
      marketCap: 24_318_002,
    },
    usdc: {
      name: "USD Coin",
      symbol: "USDC",
      address: addresses.USDC,
      icon: getLogo(addresses.USDC, chainId),
      decimals: 6,
      stable: true,
      marketCap: 164_683_113,
    },
    usdce: {
      name: "USD Coin (Bridged)",
      symbol: "USDC",
      address: addresses.USDCE,
      icon: getLogo(addresses.USDCE, chainId),
      decimals: 6,
      stable: true,
      marketCap: 195_616_136,
    },
    usdt: {
      name: "Tether USD",
      symbol: "USDT",
      address: addresses.USDT,
      icon: getLogo(addresses.USDT, chainId),
      decimals: 6,
      stable: true,
      marketCap: 794_114_828,
    },
    weth: {
      name: "Wrapped Ether",
      symbol: "WETH",
      address: addresses.WETH,
      icon: getLogo(addresses.WETH, chainId),
      decimals: 18,
      stable: false,
      marketCap: 152_034_091,
    },
  };

  return TOKEN_METADATA;
};

export default useTokenMetadata;
