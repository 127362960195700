import styled from "styled-components";
import { Header2 } from "../styles/content";

import optimism from "../assets/supporters/optimism.svg";
import chainlink from "../assets/supporters/chainlink.svg";
import pyth from "../assets/supporters/pyth.png";
import base from "../assets/supporters/base.svg";
import { GlossFrame } from "../styles/Frames";

const poweredBy: { name: string; link: string; image: string }[] = [
  {
    name: "Optimism",
    link: "https://www.optimism.io/",
    image: optimism,
  },
  {
    name: "Base",
    link: "https://www.base.org/",
    image: base,
  },
  {
    name: "Pyth",
    link: "https://pyth.network/",
    image: pyth,
  },
  {
    name: "Chainlink",
    link: "https://chain.link/",
    image: chainlink,
  },
];

const StyledPoweredBy = styled.div`
  width: 100%;
  padding: 4rem;
  max-width: 135rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const Supporters = styled.div`
  display: flex;
  gap: 4.3rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2.6rem;
  }
`;

const Frame = styled(GlossFrame)`
  flex: 1;
  max-width: 45rem;
  padding: 3.2rem;
  border-radius: 24px;

  @media (max-width: 900px) {
    padding: 2.1rem;
    height: 8.4rem;
    width: 100%;
    max-width: 100%;
    flex: none;
  }

  transition: filter 0.3s ease-in-out;
  &:hover {
    filter: brightness(1.1);

    > img {
      opacity: 1;
    }
  }
`;

const Link = styled.a`
  cursor: pointer;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.8;

  transition: opacity 0.3s ease-in-out;
`;

const PoweredBy = () => {
  return (
    <StyledPoweredBy>
      <Header2>Powered by</Header2>
      <Supporters>
        {poweredBy.map((item, index) => (
          <Frame key={index}>
            <Link href={item.link} target="_blank">
              <Icon src={item.image} />
            </Link>
          </Frame>
        ))}
      </Supporters>
    </StyledPoweredBy>
  );
};

export default PoweredBy;
