import styled from "styled-components";

import user from "../assets/nav/user.svg";
import userPink from "../assets/nav/user-pink.svg";
import newIcon from "../assets/nav/new.svg";
import newPink from "../assets/nav/new-pink.svg";
import leaderboard from "../assets/nav/leaderboard.svg";
import leaderboardPink from "../assets/nav/leaderboard-pink.svg";
import rewards from "../assets/nav/rewards.svg";
import rewardsPink from "../assets/nav/rewards-pink.svg";
import tlx from "../assets/nav/tlx.svg";
import tlxPink from "../assets/nav/tlx-pink.svg";
import { Link, useLocation } from "react-router-dom";
import {
  LEADERBOARD_PATH,
  LOCK_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  REWARDS_PATH,
  STAKE_PATH,
} from "../app/constants/paths";
import useStakedTlx from "../app/web3/views/use-staked-tlx";
import useLockerData from "../app/web3/views/use-locker-data";
import useChainData from "../app/web3/utils/use-chain-data";

const StyledMobileNav = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5.6rem;
  background: var(--bg);
  z-index: 2;
  padding: 1.6rem;
  padding-top: 0;

  @media (max-width: 1100px) {
    display: flex;
  }
`;

const Shadow = styled.div`
  position: absolute;
  top: -1.6rem;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--bg);
  filter: blur(0.8rem);
`;

const NavBar = styled.div`
  position: relative;
  background: var(--bg-1);
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  padding: 0.4rem 0.8rem;
  display: flex;
  justify-content: space-between;
  z-index: 2;
`;

interface ItemProps {
  $active: boolean;
}

const Item = styled(Link)<ItemProps>`
  position: relative;
  height: 100%;
  aspect-ratio: 1;
  background: ${(props: ItemProps) => (props.$active ? "var(--bg-3)" : "none")};
  border-radius: 0.8rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IconProps {
  $disabled?: boolean;
}

const Icon = styled.img<IconProps>`
  height: 2rem;
  opacity: ${(props: IconProps) => (props.$disabled ? 0.5 : 1)};
`;

const MobileNav = () => {
  const location = useLocation();
  const stakedTlx = useStakedTlx();
  const lockerData = useLockerData();
  const chainData = useChainData();

  return (
    <StyledMobileNav>
      <Shadow />
      <NavBar>
        {/* MINT */}
        <Item
          to={`/${MINT_PATH}`}
          $active={location.pathname === `/${MINT_PATH}`}
        >
          <Icon
            src={location.pathname === `/${MINT_PATH}` ? newPink : newIcon}
          />
        </Item>

        {/* PORTFOLIO */}
        <Item
          to={`/${PORTFOLIO_PATH}`}
          $active={location.pathname === `/${PORTFOLIO_PATH}`}
        >
          <Icon
            src={location.pathname === `/${PORTFOLIO_PATH}` ? userPink : user}
          />
        </Item>

        {/* STAKE */}
        {stakedTlx !== null && !stakedTlx.isZero() && (
          <Item
            to={`/${STAKE_PATH}`}
            $active={location.pathname === `/${STAKE_PATH}`}
          >
            <Icon
              src={location.pathname === `/${STAKE_PATH}` ? tlxPink : tlx}
            />
          </Item>
        )}

        {/* LOCK */}
        {lockerData &&
          !lockerData.isShutdown &&
          !lockerData.userLocked.isZero() && (
            <Item
              to={`/${LOCK_PATH}`}
              $active={location.pathname === `/${LOCK_PATH}`}
            >
              <Icon
                src={location.pathname === `/${LOCK_PATH}` ? tlxPink : tlx}
              />
            </Item>
          )}

        {/* LEADERBOARD */}
        <Item
          to={`/${LEADERBOARD_PATH}`}
          $active={location.pathname === `/${LEADERBOARD_PATH}`}
        >
          <Icon
            src={
              location.pathname === `/${LEADERBOARD_PATH}`
                ? leaderboardPink
                : leaderboard
            }
          />
        </Item>

        {/* REWARDS */}
        {chainData && !chainData.v2 && (
          <Item
            to={`/${REWARDS_PATH}`}
            $active={location.pathname === `/${REWARDS_PATH}`}
          >
            <Icon
              src={
                location.pathname === `/${REWARDS_PATH}` ? rewardsPink : rewards
              }
            />
          </Item>
        )}
      </NavBar>
    </StyledMobileNav>
  );
};

export default MobileNav;
