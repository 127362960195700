import styled from "styled-components";

import LeaderBoard from "./LeaderBoard";
import Seo from "../../components/Seo";
import LeaderboardBanner from "../../components/LeaderboardBanner";

const StyledLeaderBoardPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem 0;
  max-width: 120rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const LeaderBoardPage = () => {
  return (
    <StyledLeaderBoardPage>
      <Seo
        title="Leverage Rally Leaderboard"
        description="Synthetix is rewarding users who mint leverage tokens for a five week Leverage Rally!"
      />
      <LeaderboardBanner />
      <LeaderBoard />
    </StyledLeaderBoardPage>
  );
};

export default LeaderBoardPage;
