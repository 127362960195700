import useMetadataForId from "../views/use-metadata-for-id";
import useTokenId from "../views/use-token-id";

const useTokenDecimals = (tokenAddress: string | undefined) => {
  const id = useTokenId(tokenAddress);
  const metadata = useMetadataForId(id);
  if (!metadata) return null;
  return metadata.decimals;
};

export default useTokenDecimals;
