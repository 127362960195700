import useContract from "../utils/use-contract";
import abi from "../abis/synthetix-handler-abi.json";
import useAddresses from "../utils/use-addresses";

const useSynthetixHandler = () => {
  const addresses = useAddresses();
  return useContract(addresses?.SYNTHETIX_HANDLER, abi);
};

export default useSynthetixHandler;
