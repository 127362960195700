import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import useToken from "../contracts/use-token";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";
import useTokenDecimals from "../contracts/use-token-decimals";

const useTokenBalance = (
  tokenAddress: string | undefined,
  account_: string | undefined = undefined
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const { account } = useEthers();
  const supportedChain = useSupportedChain();
  const [balance, setBalance] = useState<ScaledNumber | null>(null);
  const block = useBlock();
  const token = useToken(tokenAddress);
  const tokenDecimals = useTokenDecimals(tokenAddress);
  const multicall = useMulticall();

  let address = account_ || account;

  useEffect(() => {
    if (!token) return;
    if (!address) return;
    if (!supportedChain) return;
    if (token.address !== tokenAddress) return;

    const getBalance = async () => {
      try {
        if (tokenDecimals !== null) {
          const balance = await token.balanceOf(address);
          setBalance(new ScaledNumber(balance, tokenDecimals));
        } else {
          const [balance, decimals] = await multicall([
            token.multi.balanceOf(address),
            token.multi.decimals(),
          ]);
          setBalance(new ScaledNumber(balance, decimals));
        }
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching token balance"));
      }
    };

    getBalance();
  }, [
    tokenDecimals,
    token,
    address,
    tokenAddress,
    block,
    supportedChain,
    dispatch,
    multicall,
  ]);

  return balance;
};

export default useTokenBalance;
