import useChainData from "./use-chain-data";

const useBlockExplorerLink = () => {
  const chainData = useChainData();

  const blockExplorerLink = (address: string | undefined) => {
    if (!address) return "";
    if (!chainData) return "";
    return `${chainData.blockExplorer}/address/${address}`;
  };

  return blockExplorerLink;
};

export default useBlockExplorerLink;
