import styled from "styled-components";
import { constants } from "ethers";
import { LeveragedTokenDataType } from "../../app/web3/views/use-leveraged-token-data";
import Popup from "../../components/Popup";
import useLeveragedToken from "../../app/web3/contracts/use-leveraged-token";
import { useDispatch } from "react-redux";
import InfoSection, { InfoLineType } from "../../components/InfoSection";
import useRedemptionFee from "../../app/web3/views/use-redemption-fee";
import { SubCardFrame } from "../../styles/Frames";
import Input from "../../components/Input";
import { useState } from "react";
import InputHeader from "../../components/InputHeader";
import { Splitter } from "../../styles/utils";
import { ScaledNumber } from "scaled-number";
import getSlippage from "../../app/helpers/get-slippage";
import Button from "../../components/Button";
import { setError } from "../../state/uiSlice";
import getRoundedScaledNumber from "../../app/helpers/get-rounded-scaled-number";
import useReferralCode from "../../app/web3/views/use-referral-code";
import useAccessibleMargin from "../../app/web3/views/use-accessible-margin";
import InfoBox from "../../components/InfoBox";
import shieldStar from "../../assets/greeble/shield-star.svg";
import useSupportedZapTokens from "../../app/web3/views/use-supported-zap-tokens";
import AssetSelector from "../../components/AssetSelector";
import useTokenBalances from "../../app/web3/views/use-token-balances";
import { AssetData } from "../../app/helpers/get-asset-data";
import {
  PRICE_IMPACT_GAIN_THRESHOLD,
  PRICE_IMPACT_WARNING_THRESHOLD,
} from "../../app/constants/config";
import round from "../../app/helpers/round";
import useRedeemAmountOut from "../../app/web3/views/use-redeem-amount-out";
import useApprovedAmount from "../../app/web3/views/use-approved-amount";
import useTokenPrice from "../../app/web3/views/use-token-price";
import useZap from "../../app/web3/contracts/use-zap";
import useRebatePercent from "../../app/web3/views/use-rebate-percent";
import warningIcon from "../../assets/greeble/warning.svg";
import useDecayingFeePeriod from "../../app/web3/views/use-decaying-fee-period";
import useMostRecentMint from "../../app/web3/views/use-most-recent-mint";
import useExitLoad from "../../app/web3/views/use-exit-load";
import useAddresses from "../../app/web3/utils/use-addresses";
import useTokenMetadata from "../../app/web3/views/use-token-metadata";
import getTokenId from "../../app/data/get-token-id";
import useChainData from "../../app/web3/utils/use-chain-data";
import getPriceUpdateData from "../../app/web3/utils/get-price-update-data";
import useFeedId from "../../app/web3/views/use-feed-id";
import useWrapper from "../../app/web3/contracts/use-wrapper";

const StyledRedeemLeveragedToken = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 37rem;
  grid-gap: 2.4rem;
`;

interface Props {
  open: boolean;
  close: () => void;
  data: LeveragedTokenDataType;
}

const RedeemLeveragedToken = ({ data, open, close }: Props) => {
  const dispatch = useDispatch();
  const addresses = useAddresses();
  const tokenMedata = useTokenMetadata();
  const leveragedToken = useLeveragedToken(data.addr);
  const redemptionFee = useRedemptionFee();
  const referralCode = useReferralCode();
  const accessibleMargin = useAccessibleMargin(data);
  const zapAssets = useSupportedZapTokens();
  const rebatePercent = useRebatePercent();
  const decayMinutes = useDecayingFeePeriod();
  const chainData = useChainData();
  const wrapper = useWrapper();
  const feedId = useFeedId(data.marketId);
  const mostRecentMint = useMostRecentMint(leveragedToken?.address ?? "");
  const zap = useZap();
  const [amount, setAmount] = useState<string>("");
  const [redeemAssetId, setRedeemAssetId] = useState<string>("usdc");

  const tokenOptions =
    zapAssets && tokenMedata
      ? zapAssets.map((zapAssetAddress: string) => {
          const id_ = getTokenId(zapAssetAddress, tokenMedata);
          if (!id_) throw new Error("Invalid zap asset");
          return id_;
        })
      : undefined;
  if (chainData && !chainData.v2 && tokenOptions) {
    tokenOptions.push("susd");
  }
  const tokenAddresses =
    tokenOptions && tokenMedata
      ? tokenOptions.map((id: string) => {
          const assetMetadata = tokenMedata[id];
          if (!assetMetadata) {
            throw new Error(`Asset metadata not found for ${id}`);
          }
          return assetMetadata.address;
        })
      : undefined;

  const tokenBalances = useTokenBalances(tokenAddresses);

  const assetOptions =
    tokenOptions && tokenMedata
      ? tokenOptions.map((id: string) => {
          const assetMetadata = tokenMedata[id];
          if (!assetMetadata) {
            throw new Error(`Asset metadata not found for ${id}`);
          }
          const assetData: AssetData = {
            id,
            icon: assetMetadata.icon,
            name: assetMetadata.name,
            symbol: assetMetadata.symbol,
            volume: assetMetadata.marketCap || 0,
            color: "pink",
            balance: tokenBalances
              ? tokenBalances[assetMetadata.address]
              : undefined,
            address: assetMetadata.address,
          };
          return assetData;
        })
      : undefined;

  const hasClaimedReferralCode = referralCode !== null && referralCode !== "";
  const slippage = getSlippage(data.leverage.toNumber());

  const desiredRedeemAmount = getRoundedScaledNumber(amount, data.userBalance);
  const availableRedeemAmount = accessibleMargin
    ? accessibleMargin.div(data.exchangeRate).mul((1 - slippage).toString())
    : null;
  const requiresSteps =
    availableRedeemAmount && desiredRedeemAmount.gt(availableRedeemAmount);

  const redeemWithZap =
    redeemAssetId !== "susd" && !!chainData && !chainData.v2;
  const redeemWithWrapper = chainData && chainData.v2;

  const approvalAddress = redeemWithZap
    ? zap?.address
    : redeemWithWrapper
    ? wrapper?.address
    : leveragedToken?.address;

  const redeemAmount = availableRedeemAmount
    ? availableRedeemAmount.min(desiredRedeemAmount)
    : ScaledNumber.fromUnscaled(0);
  const exitLoad = useExitLoad(leveragedToken?.address ?? "", redeemAmount);

  const redeemTokenData = tokenMedata ? tokenMedata[redeemAssetId] : null;
  if (tokenMedata && !redeemTokenData) throw new Error("Invalid mint token");
  const approvedAmount = useApprovedAmount(data.addr, approvalAddress);
  const expectedOut = useRedeemAmountOut(
    approvedAmount !== null ? redeemAmount : null,
    redeemTokenData?.address,
    redeemWithZap,
    data.addr,
    data.exchangeRate,
    redeemTokenData?.decimals,
    exitLoad
  );

  const redeemTokenPrice = useTokenPrice(redeemTokenData?.address);
  const susdPrice = useTokenPrice(addresses?.SUSD);
  const priceImpact =
    redeemTokenPrice &&
    data &&
    expectedOut &&
    susdPrice &&
    !redeemAmount.isZero() &&
    redemptionFee &&
    !expectedOut.isZero()
      ? redeemTokenPrice
          .mul(expectedOut)
          .div(ScaledNumber.fromUnscaled(1).sub(redemptionFee))
          .sub(redeemAmount.mul(data.exchangeRate).mul(susdPrice))
          .div(redeemAmount.mul(data.exchangeRate).mul(susdPrice))
      : null;

  const priceImpactInfo: InfoLineType | undefined = redeemWithZap
    ? {
        label: "Price Impact",
        error:
          !!priceImpact &&
          priceImpact.toNumber() < -PRICE_IMPACT_WARNING_THRESHOLD,
        success:
          !!priceImpact && priceImpact.toNumber() > PRICE_IMPACT_GAIN_THRESHOLD,
        tooltip: `Redemptions by default are done in sUSD. Selecting ${
          redeemTokenData?.symbol ?? "---"
        } requires a swap as part of the redemption. Swapping large amounts can cause price impact. To minimize price impact, consider redeeming for sUSD and then swapping your sUSD for ${
          redeemTokenData?.symbol ?? "---"
        } on 1inch or Odos.`,
        value: priceImpact
          ? `${!priceImpact.isNegative() ? "+" : ""} ${round(
              priceImpact.mul(100).toNumber(),
              3
            ).toString()}`
          : "--",
        unit: "%",
      }
    : undefined;

  const priceImpactInfoList: InfoLineType[] =
    priceImpactInfo !== undefined ? [priceImpactInfo!] : [];
  const needsApproval = redeemWithZap || redeemWithWrapper;
  const approved =
    !needsApproval ||
    (approvedAmount !== null && approvedAmount.gte(redeemAmount));

  const now = new Date();
  const timePassed = mostRecentMint
    ? now.getTime() - mostRecentMint.getTime()
    : 0;
  const minutesPassed = timePassed / 1000 / 60;
  const isChargedExitLoad =
    !mostRecentMint || (decayMinutes && minutesPassed < decayMinutes);
  const minutesRemaining =
    decayMinutes && mostRecentMint ? decayMinutes - minutesPassed : 0;

  const exitLoadInfo: InfoLineType | undefined = isChargedExitLoad
    ? {
        label: "Exit Load",
        tooltip: `A Exit Load is charged on redemptions within ${decayMinutes} minutes of minting or for users that have not minted this token on this address.`,
        value: exitLoad
          ? exitLoad.div(redeemAmount).mul(100).toNumber().toFixed(3)
          : "--",
        unit: "%",
      }
    : undefined;
  const exitLoadInfoList: InfoLineType[] =
    exitLoadInfo !== undefined ? [exitLoadInfo!] : [];

  return (
    <Popup open={open} close={close} header={`Redeem ${data.symbol}`}>
      <StyledRedeemLeveragedToken>
        <SubCardFrame>
          <Splitter>
            <InputHeader
              header="Amount"
              tooltip={`The amount of ${data.symbol} you want to redeem`}
            />
            <Input
              number
              value={amount}
              setValue={setAmount}
              placeholder={`Enter ${data.symbol} amount`}
              max={data.userBalance.toNumber()}
            />
          </Splitter>
        </SubCardFrame>
        <SubCardFrame>
          <Splitter>
            <InputHeader
              header="Receive"
              tooltip="The asset you will receive after redeeming. sUSD is preferred as it has no price impact for the redemption."
            />
            <AssetSelector
              options={assetOptions}
              active={redeemAssetId}
              setActive={setRedeemAssetId}
            />
          </Splitter>
        </SubCardFrame>
        {requiresSteps && (
          <InfoBox
            warning
            icon={shieldStar}
            text="Your redemption amount exceeds the accessible margin of the Synthetix position. Your redemption will be processed in multiple steps."
          />
        )}
        {isChargedExitLoad && mostRecentMint && (
          <InfoBox
            card
            warning
            text={`Wait ${Math.ceil(
              minutesRemaining
            )} minutes to avoid the Exit Load`}
            icon={warningIcon}
          />
        )}
        <InfoSection
          lines={[
            ...priceImpactInfoList,
            ...exitLoadInfoList,
            {
              label: "Net Redemption Fee",
              tooltip: `The ${
                redemptionFee ? redemptionFee.toPercent() : "--%"
              } redemption fee is charged on the amount of sUSD redeemed multiplied by the target leverage of the position.${
                hasClaimedReferralCode
                  ? ` Because you have used the referral code '${referralCode}' you get a ${
                      rebatePercent ? rebatePercent.toPercent() : "--%"
                    } rebate on your redemption fee, resulting in a net redemption fee of ${
                      redemptionFee && rebatePercent
                        ? redemptionFee
                            .mul(1 - rebatePercent.toNumber())
                            .mul(100)
                            .toCryptoString()
                        : "--"
                    }%.`
                  : ""
              }`,
              value:
                redemptionFee && rebatePercent
                  ? redemptionFee
                      .mul(
                        hasClaimedReferralCode
                          ? 1 - rebatePercent.toNumber()
                          : 1
                      )
                      .mul(100)
                      .toCryptoString()
                  : "--",
              unit: "%",
            },
            {
              label: `Expected ${redeemTokenData?.symbol ?? "---"} Received`,
              tooltip: `The expected amount of ${
                redeemTokenData?.symbol ?? "--"
              } you will receive after redeeming. Slippage may affect the final amount`,
              value: expectedOut ? expectedOut.toCryptoString() : "---",
              unit: redeemTokenData?.symbol ?? "---",
            },
          ]}
        />
        <Button
          wide
          primary
          web3
          loading={
            !!leveragedToken &&
            !expectedOut &&
            !desiredRedeemAmount.isZero() &&
            !leveragedToken &&
            data.hasPendingLeverageUpdate &&
            !zap
          }
          disabled={desiredRedeemAmount.isZero()}
          action={async () => {
            if (!leveragedToken) return;
            if (!expectedOut) return;
            if (!addresses) return;
            if (!redeemTokenData) return;

            if ((redeemWithZap || redeemWithWrapper) && !approved) {
              try {
                const tx = await leveragedToken.approve(
                  redeemWithZap ? addresses.ZAP_SWAP : addresses.WRAPPER_HELPER,
                  redeemAmount.value
                );
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Redeem/Approve",
                  })
                );
              }
              return;
            }

            if (redeemWithZap) {
              if (!zap) return;

              try {
                const tx = await zap.redeem(
                  redeemTokenData.address,
                  data.addr,
                  redeemAmount.value,
                  expectedOut.mul((1 - slippage).toString()).value
                );
                await tx.wait();
                setAmount("");
                close();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Redeem/RedeemZap",
                  })
                );
              }
              return;
            }

            if (redeemWithWrapper) {
              if (!feedId) return;
              if (!wrapper) return;

              try {
                const priceUpdateData = getPriceUpdateData(feedId);
                const tx = await wrapper.redeemIntoUSDC(
                  leveragedToken.address,
                  redeemAmount.value,
                  expectedOut.mul((1 - slippage).toString()).value,
                  priceUpdateData,
                  constants.HashZero
                );
                await tx.wait();
                setAmount("");
                close();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Redeem/RedeemWrapper",
                  })
                );
              }
              return;
            }

            try {
              const tx = await leveragedToken.redeem(
                redeemAmount.value,
                expectedOut.mul((1 - slippage).toString()).value
              );
              await tx.wait();
              setAmount("");
              close();
            } catch (e: any) {
              dispatch(
                setError({
                  message: e.message,
                  source: "RedeemLeveragedToken",
                })
              );
            }
          }}
        >
          {desiredRedeemAmount.isZero()
            ? "Enter an amount"
            : needsApproval && !approved
            ? `Approve ${data.symbol}`
            : requiresSteps
            ? `Redeem ${redeemAmount.toCryptoString()} ${data.symbol}`
            : `Redeem ${data.symbol}`}
        </Button>
      </StyledRedeemLeveragedToken>
    </Popup>
  );
};

export default RedeemLeveragedToken;
