import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";
import { Contract, providers } from "ethers";
import abi from "../abis/erc20-abi.json";
import { createMulti } from "../utils/use-contract";

const useTokenBalances = (
  tokenAddress: string[] | undefined,
  account_: string | undefined = undefined
): Record<string, ScaledNumber> | null => {
  const dispatch = useDispatch();
  const { account, library } = useEthers();
  const supportedChain = useSupportedChain();
  const [balances, setBalances] = useState<Record<string, ScaledNumber> | null>(
    null
  );
  const block = useBlock();
  const multicall = useMulticall();

  let address = account_ || account;

  const tokenCount = tokenAddress?.length || 0;
  useEffect(() => {
    const getBalance = async () => {
      if (!address) return;
      if (!supportedChain) return;
      if (!library) return;
      if (!(library instanceof providers.JsonRpcProvider)) return;
      if (!tokenAddress) return;

      try {
        const balances_ = await multicall(
          tokenAddress.map((token) => {
            const contract_ = new Contract(
              token,
              abi,
              library.getSigner()
            ) as any;
            const multi = createMulti(token, abi);
            contract_.multi = multi;

            return contract_.multi.balanceOf(address);
          })
        );
        const decimals_ = await multicall(
          tokenAddress.map((token) => {
            const contract_ = new Contract(
              token,
              abi,
              library.getSigner()
            ) as any;
            const multi = createMulti(token, abi);
            contract_.multi = multi;

            return contract_.multi.decimals();
          })
        );

        if (!balances_ || !decimals_) return;

        const balances = tokenAddress.reduce((acc, token, i) => {
          acc[token] = new ScaledNumber(balances_[i], decimals_[i]);
          return acc;
        }, {} as Record<string, ScaledNumber>);

        setBalances(balances);
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching token balances"));
      }
    };

    getBalance();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, tokenCount, block, supportedChain, dispatch, multicall]);

  return balances;
};

export default useTokenBalances;
