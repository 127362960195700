import styled from "styled-components";
import { shortenAddress, useEthers } from "@usedapp/core";

import Button from "./Button";
import AssetSelector from "./AssetSelector";
import SUPPORTED_CHAINS from "../app/constants/supported-chains";
import useChainData from "../app/web3/utils/use-chain-data";
import useMainChainData from "../app/web3/utils/use-main-chain-data";

const StyledConnector = styled.div<{ $connected: boolean }>`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 900px) {
    > :first-child {
      display: ${({ $connected }) => ($connected ? "flex" : "none")};
    }
    > :last-child {
      display: ${({ $connected }) => ($connected ? "none" : "flex")};
    }
  }
`;

const Connector = () => {
  const { account, activateBrowserWallet, active } = useEthers();
  const { switchNetwork } = useEthers();
  const chainData = useChainData();
  const mainChainData = useMainChainData();

  const connected = active && account;

  return (
    <StyledConnector $connected={!!connected}>
      <AssetSelector
        small
        options={SUPPORTED_CHAINS.map((chain) => chain.assetData)}
        active={chainData ? chainData.assetData.id : mainChainData.assetData.id}
        setActive={(assetId: string) => {
          const chain = SUPPORTED_CHAINS.find(
            (chain) => chain.assetData.id === assetId
          );
          if (!chain) return;
          switchNetwork(chain.chainId);
        }}
      />
      <Button
        primary={!connected}
        action={() => {
          if (!connected) activateBrowserWallet();
        }}
      >
        {connected ? shortenAddress(account) : "Connect"}
      </Button>
    </StyledConnector>
  );
};

export default Connector;
