import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";
import useToken from "../contracts/use-token";
import useZap from "../contracts/use-zap";
import useLeveragedToken from "../contracts/use-leveraged-token";
import tenderlySimulation from "../utils/tenderly-simulation";
import { useEthers } from "@usedapp/core";
import { useDebounce } from "@usedapp/core/dist/esm/src/hooks";
import { DEBOUNCE_DELAY } from "../../constants/config";
import useAddresses from "../utils/use-addresses";
import useChainData from "../utils/use-chain-data";
import useTokenDecimals from "../contracts/use-token-decimals";

const useMintAmountOut = (
  mintAmount_: ScaledNumber | null,
  mintTokenAddress: string | undefined,
  mintWithZap: boolean,
  leveragedTokenAddress: string | null,
  leveragedTokenExchangeRate: ScaledNumber | null
): ScaledNumber | null => {
  const dispatch = useDispatch();
  const addresses = useAddresses();
  const supportedChain = useSupportedChain();
  const [mintAmountOut, setMintAmountOut] = useState<ScaledNumber | null>(null);
  const multicall = useMulticall();
  const mintToken = useToken(mintTokenAddress);
  const mintTokenDecimals = useTokenDecimals(mintTokenAddress);
  const chainData = useChainData();
  const zap = useZap();
  const leveragedToken = useLeveragedToken(leveragedTokenAddress);
  const { account } = useEthers();
  const mintAmount = useDebounce(mintAmount_, DEBOUNCE_DELAY);

  const mintAmountValue = mintAmount !== null ? mintAmount.value : null;

  useEffect(() => {
    if (!supportedChain) return;
    if (!chainData) return;
    if (!leveragedTokenAddress) return;
    if (!account) return;
    if (mintAmountValue === null) return;
    if (mintAmountValue === BigInt(0)) {
      setMintAmountOut(new ScaledNumber(0));
      return;
    }
    if (!leveragedToken) return;
    if (!leveragedTokenExchangeRate) return;
    if (!addresses) return;
    if (mintTokenAddress === undefined) return;
    if (!mintTokenDecimals) return;

    const getBalance = async () => {
      try {
        if (mintWithZap) {
          if (!zap) return;
          if (!mintToken) return;

          const response = await tenderlySimulation([
            {
              from: account,
              to: mintTokenAddress,
              data: mintToken.interface.encodeFunctionData("approve", [
                zap.address,
                mintAmountValue,
              ]),
            },
            {
              from: account,
              to: addresses.ZAP_SWAP,
              data: zap.interface.encodeFunctionData("mint", [
                mintTokenAddress,
                leveragedTokenAddress,
                mintAmountValue,
                0,
              ]),
            },
          ]);
          const assetChanges = response.result[1].assetChanges;
          if (!assetChanges) return;
          const rawAmount = assetChanges.find(
            (change: any) =>
              change.to === account.toLowerCase() &&
              change.assetInfo.contractAddress ===
                leveragedTokenAddress.toLowerCase()
          ).rawAmount;
          const amountOut = BigInt(rawAmount);
          setMintAmountOut(new ScaledNumber(amountOut));
        } else {
          const mintAmountOut = new ScaledNumber(
            mintAmountValue,
            mintTokenDecimals
          );
          setMintAmountOut(mintAmountOut.div(leveragedTokenExchangeRate));
        }
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error getting Mint Amount Out"));
      }
    };

    getBalance();
  }, [
    supportedChain,
    addresses,
    dispatch,
    multicall,
    mintAmountValue,
    mintToken,
    leveragedTokenAddress,
    mintTokenAddress,
    mintWithZap,
    zap,
    leveragedToken,
    account,
    leveragedTokenExchangeRate,
    chainData,
    mintTokenDecimals,
  ]);

  const resetTrigger = mintAmount_ !== null ? mintAmount_.value : null;
  useEffect(() => {
    setMintAmountOut(null);
  }, [resetTrigger, mintTokenAddress]);

  return mintAmountOut;
};

export default useMintAmountOut;
