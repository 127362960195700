import useContract from "../utils/use-contract";
import abi from "../abis/leveraged-token-abi.json";
import abiV2 from "../abis/leveraged-token-abi-v2.json";
import useChainData from "../utils/use-chain-data";

const useLeveragedToken = (tokenAddress: string | null) => {
  const chainData = useChainData();
  return useContract(tokenAddress, chainData?.v2 ? abiV2 : abi);
};

export default useLeveragedToken;
