import styled from "styled-components";
import Section from "./Section";

interface ContainerProps {
  $background?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 5.5rem;

  @media (max-width: 900px) {
    height: auto;
    padding: 0 2rem;
    margin-top: ${(props) => (props.$background ? "-33.5rem" : "4.4rem")};
  }
`;

const StyledTextSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.5;

  @media (max-width: 900px) {
    flex: 1;
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Header = styled.h2`
  font-size: 6.8rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 1.6rem;
  color: var(--main);
  max-width: 70rem;
  text-align: left;

  @media (max-width: 900px) {
    font-size: 4.8rem;
    margin-bottom: 1.2rem;
    max-width: 100%;
  }
`;

const SubHeader = styled.p`
  font-size: 2.4rem;
  font-weight: 400;
  color: var(--sub);
  max-width: 60rem;
  text-align: left;

  @media (max-width: 900px) {
    max-width: 40rem;
  }
`;

const ImageContainer = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: flex;
    position: relative;
    height: 100vw;
    width: calc(100% + 4rem);
    overflow: hidden;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110vw;
  aspect-ratio: 1;
  display: flex;
  filter: contrast(1.07) brightness(1);
  mix-blend-mode: lighten;
`;

interface Props {
  header: string;
  subHeader: string;
  image: string;
  percentage: number;
  section: number;
  background?: boolean;
}

const TextSection = ({
  header,
  subHeader,
  image,
  background,
  percentage,
  section,
}: Props) => {
  return (
    <Section percentage={percentage} section={section}>
      <Container $background={background}>
        <StyledTextSection>
          <ImageContainer>
            <Image src={image} alt={`${header}-image`} />
          </ImageContainer>
          <Content>
            <Header>{header}</Header>
            <SubHeader>{subHeader}</SubHeader>
          </Content>
        </StyledTextSection>
      </Container>
    </Section>
  );
};

export default TextSection;
