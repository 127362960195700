import { ScaledNumber } from "scaled-number";
import { ASSET_METADATA } from "../data/asset-metadata";
import SUPPORTED_CHAINS from "../constants/supported-chains";

const assetIdToSymbol = (assetId: string) => {
  const replace = ["sETH", "sBTC", "sLINK"];
  return replace.includes(assetId) ? assetId.substring(1) : assetId;
};

export interface AssetData {
  id: string;
  icon: string;
  name: string;
  symbol?: string;
  volume: number;
  coingeckoId?: string;
  color: string;
  comingSoon?: boolean;
  balance?: ScaledNumber;
  address?: string;
  price?: ScaledNumber;
}

export const getAssetData = (assetId: string, chainId?: number | null) => {
  const symbol = assetIdToSymbol(assetId);
  const metadata = ASSET_METADATA[symbol];
  if (!metadata) throw new Error(`No metadata for ${assetId}`);

  const chain =
    chainId && SUPPORTED_CHAINS.filter((chain) => chain.chainId === chainId)[0];

  const assetData: AssetData = {
    id: assetId,
    icon: `https://synthetixio.github.io/synthetix-assets/markets/${symbol}.svg`,
    name: metadata.name,
    symbol,
    volume: metadata.volume,
    coingeckoId: metadata.coingeckoId,
    color: metadata.color,
    comingSoon: chain
      ? !Object.keys(chain.leveragedTokens).includes(assetId)
      : false,
  };

  return assetData;
};
