import styled from "styled-components";

export const PageHeader = styled.h1`
  font-size: 3.2rem;
  font-weight: 500;
  width: 100%;
  margin-bottom: 2.4rem;
`;

export const Header1 = styled.h1`
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 4rem;

  @media (max-width: 900px) {
    font-size: 4rem;
  }
`;

export const Header2 = styled.h2`
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 4rem;

  @media (max-width: 900px) {
    margin-top: 12rem;
    width: 100%;
  }
`;

export const Header3 = styled.h3`
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 3rem;

  @media (max-width: 900px) {
    font-size: 2.8rem;
  }
`;

export const Header4 = styled.h4`
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.6rem;
`;

export const Paragraph = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--sub);
  line-height: 1.3;
  margin-top: 1.6rem;

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
`;

export const List = styled.ul`
  padding-left: 3rem;
`;

export const ListItem = styled.li`
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--sub);
  line-height: 1.3;
  margin-top: 0.8rem;

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
`;

export const Link = styled.a`
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--sub);
  line-height: 1.3;
  text-decoration: underline;

  @media (max-width: 900px) {
    font-size: 1.6rem;
  }
`;
