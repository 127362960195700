import useContract from "../utils/use-contract";
import abi from "../abis/leveraged-token-helper-abi.json";
import abiV2 from "../abis/leveraged-token-helper-abi-v2.json";
import useAddresses from "../utils/use-addresses";
import useChainData from "../utils/use-chain-data";

const useLeveragedTokenHelper = () => {
  const addresses = useAddresses();
  const chainData = useChainData();
  return useContract(
    addresses?.LT_HELPER,
    chainData && chainData.v2 ? abiV2 : abi
  );
};

export default useLeveragedTokenHelper;
