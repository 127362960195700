import styled from "styled-components";

import nextPageImage from "../assets/ui/next-page.svg";
import lastPageImage from "../assets/ui/last-page.svg";

const StyledPagination = styled.div`
  padding: 1.05rem 1.25rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const StyledButton = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  displa: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Icon = styled.img<{ rotate?: string; disabled?: boolean }>`
  height: 0.9rem;
  transform: ${(props) => (props.rotate ? `rotate(${props.rotate})` : "")};
  opacity: ${(props) => (props.disabled ? "50%" : "100%")};
`;

const Text = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--sub);
`;

interface Props {
  isNextButtonDisabled: boolean;
  isPreviousButtonDisabled: boolean;
  totalPages?: number;
  page: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onFirstPage: () => void;
  onLastPage: () => void;
}

const Pagination = ({
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  onPreviousPage,
  onNextPage,
  onFirstPage,
  onLastPage,
  totalPages,
  page,
}: Props) => {
  return (
    <StyledPagination>
      <StyledButton disabled={isPreviousButtonDisabled} onClick={onFirstPage}>
        <Icon
          disabled={isPreviousButtonDisabled}
          rotate="180deg"
          src={lastPageImage}
        />
      </StyledButton>
      <StyledButton
        disabled={isPreviousButtonDisabled}
        onClick={onPreviousPage}
      >
        <Icon
          disabled={isPreviousButtonDisabled}
          rotate="180deg"
          src={nextPageImage}
        />
      </StyledButton>
      {totalPages ? (
        <Text>
          Page {page} of {totalPages}
        </Text>
      ) : null}
      <StyledButton disabled={isNextButtonDisabled} onClick={onNextPage}>
        <Icon disabled={isNextButtonDisabled} src={nextPageImage} />
      </StyledButton>
      <StyledButton disabled={isNextButtonDisabled} onClick={onLastPage}>
        <Icon disabled={isNextButtonDisabled} src={lastPageImage} />
      </StyledButton>
    </StyledPagination>
  );
};

export default Pagination;
