import { useQuery } from "@tanstack/react-query";

export interface LeaderboardItem {
  epoch_start: string;
  account: string;
  total_fees_paid: string;
  fees_paid_pct: string;
  rank: string;
}

const getLeaderboardUrl = (network?: number) => {
  if (network === 10) {
    return "https://api.synthetix.io/v3/optimism/lt-leaderboard";
  }
  return "https://api.synthetix.io/v3/base/lt-leaderboard";
};

const getWeeklyRewardAmount = (network?: number) => {
  if (network === 10) {
    return 15000;
  }
  return 30000;
};

const useLeaderboard = (network?: number) => {
  return useQuery({
    queryKey: ["leaderboard", network],
    queryFn: async () => {
      const url = getLeaderboardUrl(network);
      const response = await fetch(url);
      const data: LeaderboardItem[] = await response.json();
      const rewardAmount = getWeeklyRewardAmount(network);

      return data
        .map((item) => ({
          account: item.account,
          date: new Date(item.epoch_start),
          rank: Number(item.rank),
          totalFeesPaid: Number(item.total_fees_paid),
          estimatedRewards: Number(item.fees_paid_pct) * rewardAmount,
        }))
        .sort((a, b) => (a.rank < b.rank ? -1 : 1));
    },
  });
};

export default useLeaderboard;
