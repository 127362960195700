import { DOCS, TLX_TO_SNX_MIGRATION } from "./urls";

export interface LinkType {
  name: string;
  path: string | null;
  external: boolean;
}

export const LINKS: LinkType[] = [
  {
    name: "Docs",
    path: DOCS,
    external: true,
  },
  {
    name: "Migrate TLX to SNX",
    path: TLX_TO_SNX_MIGRATION,
    external: true,
  },
];
