import { useEffect, useState } from "react";
import { ChartType } from "./use-chart";
import leveragedTokenAbi from "../web3/abis/leveraged-token-abi.json";
import useChainData from "../web3/utils/use-chain-data";
import { Contract, providers } from "ethers";
import { ScaledNumber } from "scaled-number";

// Config
const PERIOD_DAYS = 7;
const SAMPLES = 14;

// Constants
const SECONDS_PER_BLOCK = 2;
const SECONDS_PER_DAY = 60 * 60 * 24;
const SECONDS_STEP = (PERIOD_DAYS * SECONDS_PER_DAY) / SAMPLES;
const BLOCK_STEP = SECONDS_STEP / SECONDS_PER_BLOCK;

const useLeveragedTokenChart = (
  address: string | undefined
): ChartType | null | undefined => {
  const [chart, setChart] = useState<ChartType | null | undefined>(null);
  const chainData = useChainData();

  const rpc = chainData ? chainData.rpc : null;

  useEffect(() => {
    if (!address) return;
    if (!rpc) return;

    const now = new Date();

    const getAmount = async () => {
      try {
        const provider = new providers.JsonRpcProvider(rpc);
        const leveragedToken = new Contract(
          address,
          leveragedTokenAbi,
          provider
        ) as any;
        const currentBlock = await provider.getBlockNumber();
        const calls = Array.from({ length: SAMPLES }, (_, i) => {
          const blockNumber = currentBlock - Math.floor(i * BLOCK_STEP);
          return leveragedToken.exchangeRate({
            blockTag: blockNumber,
          });
        });

        const results = await Promise.all(calls);
        const prices = results.map((price, i) => ({
          time: new Date(now.getTime() - i * SECONDS_PER_DAY * 1000),
          price: new ScaledNumber(price).toNumber(),
        }));
        setChart({ prices });
      } catch (e: any) {
        setChart(undefined);
        console.log(e);
      }
    };

    getAmount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    setChart(null);
  }, [address, rpc]);

  return chart;
};

export default useLeveragedTokenChart;
