import { getAssetData } from "./get-asset-data";

const getLeveragedTokenSymbol = (
  assetId: string,
  leverage: number,
  isLong: boolean,
  chainId: number | null
) => {
  const assetData = getAssetData(assetId, chainId);

  return `${assetData.symbol}${leverage}${isLong ? "L" : "S"}`;
};

export default getLeveragedTokenSymbol;
