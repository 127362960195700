import useTokenMetadata from "./use-token-metadata";

const useMetadataForId = (id: string | null) => {
  const tokenMetadata = useTokenMetadata();
  if (!id) return null;
  if (!tokenMetadata) return null;
  return tokenMetadata[id];
};

export default useMetadataForId;
