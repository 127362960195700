import styled from "styled-components";
import { useEffect, useState } from "react";

import { AssetData, getAssetData } from "../app/helpers/get-asset-data";
import { ALL_ASSET_IDS } from "../app/data/asset-metadata";
import AssetSelectorPopup from "./AssetSelectorPopup";
import chevron from "../assets/ui/chevron.svg";
import useChainId from "../app/web3/utils/use-chain-id";

const StyledAssetSelector = styled.button<{ $small?: boolean }>`
  display: flex;
  height: ${({ $small }) => ($small ? "4rem" : "4.8rem")};
  padding: 0.7rem;
  padding-right: 1.2rem;
  border: var(--border);
  border-radius: var(--border-radius);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--bg-1);
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 1.4rem;
`;

const Icon = styled.img`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--sub);
`;

const TextSection = styled.div<{ $fullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.7rem;
  height: ${({ $fullHeight }) => ($fullHeight ? "100%" : "auto")};
`;

const Name = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.05;
`;

const Symbol = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--sub);
  text-align: left;
  line-height: 1.05;
`;

const ChangeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  border-radius: 1.2rem;
  height: 2.4rem;
  padding: 0 0.8rem;
  font-size: 1.4rem;
  background: var(--bg-3);
  filter: brightness(1);

  transition: filter 0.3s ease-in-out;
  &:hover {
    filter: brightness(1.1);
  }

  @media (max-width: 900px) {
    font-size: 1.6rem;
    padding: 0 2.4rem;
  }
`;

const ChangeIcon = styled.img`
  height: 1.1rem;
`;

interface Props {
  options?: AssetData[];
  active: string;
  setActive: (assetId: string) => void;
  small?: boolean;
}

const AssetSelector = ({ options, active, setActive, small }: Props) => {
  const chainId = useChainId();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!options) {
    options = ALL_ASSET_IDS.map((option) => getAssetData(option, chainId)).sort(
      (a, b) => (a.comingSoon ? 1 : b.comingSoon ? -1 : 0)
    );
  }
  const activeAsset = options.find((option) => option.id === active);

  const firstId = options && options.length > 0 ? options[0].id : null;
  useEffect(() => {
    if (!active && firstId) {
      setActive(firstId);
    }
  }, [firstId, active, setActive]);

  return (
    <>
      <StyledAssetSelector $small={small} onClick={() => setIsOpen(true)}>
        <LeftSection>
          <Icon src={activeAsset?.icon} />
          <TextSection $fullHeight={!!activeAsset?.symbol}>
            <Name>{activeAsset?.name || "---"}</Name>
            {activeAsset?.symbol && (
              <Symbol>{activeAsset?.symbol || "---"}</Symbol>
            )}
          </TextSection>
        </LeftSection>
        {small && <ChangeIcon src={chevron} alt="Change" />}
        {!small && <ChangeButton>Change</ChangeButton>}
      </StyledAssetSelector>
      <AssetSelectorPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={options}
        setActive={setActive}
      />
    </>
  );
};

export default AssetSelector;
