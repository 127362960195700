import styled from "styled-components";
import banner from '../assets/leaderboard-banner.png';

const StyledBanner = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  padding-top: 2.9rem;
  padding-left: 7.6rem;
  width: 100%;
  height: 20rem;

  background: var(--bg-1);
  background-image: url(${banner});
  border: var(--border);

  @media (max-width: 900px) {
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;

const Text = styled.p`
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--sub);
`;

const Title = styled.div`
  font-size: 4rem;
  font-weight: 800;
  color: var(--main);
`;

const LeaderboardBanner = () => {
  return (
    <StyledBanner>
      <Text>Jan 21 — Feb, 24 2025</Text>
      <Title>
        Leverage Rally <br />
        Leaderboard
      </Title>
    </StyledBanner>
  );
};

export default LeaderboardBanner;
