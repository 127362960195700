import styled from "styled-components";

const Container = styled.div`
  position: relative;

  &:hover {
    & > div {
      display: flex;
    }
  }
`;

const StyledComingSoon = styled.div`
  position: absolute;
  padding: 0.8rem 1.2rem;
  border: var(--border);
  border-radius: var(--border-radius);
  background: var(--bg-1);
  top: calc(100% - 0.5rem);
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
  display: none;
  color: var(--main);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  white-space: nowrap;
`;

const ChildrenContainer = styled.div<{ $comingSoon?: boolean }>`
  opacity: ${({ $comingSoon }) => ($comingSoon ? 0.5 : 1)};
`;

interface Props {
  children: React.ReactNode;
  comingSoon?: boolean;
}

const ComingSoon = ({ children, comingSoon }: Props) => {
  return (
    <Container>
      {comingSoon && <StyledComingSoon>Coming soon...</StyledComingSoon>}
      <ChildrenContainer $comingSoon={comingSoon}>{children}</ChildrenContainer>
    </Container>
  );
};

export default ComingSoon;
