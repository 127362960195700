import styled from "styled-components";
import { Header2 } from "../styles/content";
import { GlossFrame } from "../styles/Frames";

const StyledHowItWorks = styled.div`
  width: 100%;
  padding: 4rem;
  max-width: 135rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    padding: 0;
  }
`;

const Items = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.4rem;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Item = styled(GlossFrame)`
  padding: 3.2rem;
  border-radius: var(--border-radius);
  border-radius: 24px;

  @media (max-width: 900px) {
    padding: 1.4rem 2.4rem;
  }
`;

const Number = styled.div`
  color: var(--main);
  font-size: 10.8rem;
  font-weight: 300;
  margin-bottom: 1.6rem;
  line-height: 1;
`;

const Header = styled.h3`
  font-size: 4.8rem;
  font-weight: 400;
  color: var(--main);
  line-height: 1;
  margin-bottom: 1.6rem;
`;

const Body = styled.p`
  font-size: 2rem;
  font-weight: 400;
  color: var(--main);
`;

const HowItWorks = () => {
  return (
    <StyledHowItWorks>
      <Header2>How it works</Header2>
      <Items>
        <Item>
          <Number>01</Number>
          <Header>Mint</Header>
          <Body>
            Choose your target asset, leverage and direction, and mint leveraged
            tokens using stablecoins or ETH
          </Body>
        </Item>
        <Item>
          <Number>02</Number>
          <Header>Hold</Header>
          <Body>
            Lean back and relax while automatic rebalancing manages your
            position
          </Body>
        </Item>
        <Item>
          <Number>03</Number>
          <Header>Redeem</Header>
          <Body>Redeem your position at any time for stablecoins or ETH</Body>
        </Item>
      </Items>
    </StyledHowItWorks>
  );
};

export default HowItWorks;
